<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <!-- <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Nuevo</span>
              </b-button> -->
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="2"
          >
            <b-button
              variant="primary"
              @click="openModalControlFise('new')"
            >
              <span class="text-nowrap">Nuevo</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(razonSocial)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`data:image/jpeg;base64,${data.item.foto}`"
                :text="avatarText(`${data.item.razonSocial}`)"
                :variant="`light-${getColorAvatar()}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.razonSocial }}
            </span>
            <small class="text-muted">{{ data.item.representanteLegal }}</small>
            <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
          </b-media>
        </template>
        <!-- Column: estado_venta -->
        <template #cell(estado_venta)="data">
          <b-badge
            v-if="data.item.fechaAnulacion == null"
            variant="success"
          >
            Vigente
          </b-badge>
          <b-badge
            v-if="data.item.fechaAnulacion != null"
            variant="danger"
          >
            Anulado
          </b-badge>

        </template>
        <template #cell(comprobanteTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-4 font-weight-bolder">
                {{ data.item.nroComprobante }}
              </div>
              <div class="font-small-3 text-muted">
                Fecha : {{ data.item.fechaEmision }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(proveedorTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.proveedor.razonSocial }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.proveedor.ruc }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(transportistaDenominacion)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.transportistaDenominacion }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.transportistaDocumentoNumero }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(estadoCredito)="data">
          <template v-if="data.item.estadoCredito === 'PAGADO'">
            <b-badge
              pill
              variant="light-success"
            >
              {{ data.item.estadoCredito.toUpperCase() }}
            </b-badge>
          </template>
          <template v-if="data.item.estadoCredito === 'PENDIENTE'">
            <b-badge
              pill
              variant="light-primary"
            >
              {{ data.item.estadoCredito.toUpperCase() }}
            </b-badge>
          </template>
          <template v-if="data.item.estadoCredito === 'ENVIADO'">
            <b-badge
              pill
              variant="light-warning"
            >
              {{ data.item.estadoCredito.toUpperCase() }}
            </b-badge>
          </template>
        </template>
        <template #cell(clienteTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.clienteNombre }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.clienteRuc }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(clienteFinalTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div
                v-if="data.item.venta.clienteFinal !== null"
                class="font-small-3 font-weight-bolder"
              >
                {{ data.item.venta.clienteFinal.nombreComercial }}
              </div>
              <div
                v-if="data.item.venta.clienteFinal !== null"
                class="font-small-2 text-muted"
              >
                {{ data.item.venta.clienteRuc.length==11?'RUC':'DNI' }}: {{ data.item.venta.clienteRuc }}
              </div>
              <div
                v-if="data.item.venta.clienteFinal === null"
                class="font-small-4 text-muted"
              >
                <strong>No registro Cliente</strong>
              </div>
            </div>
          </div>
        </template>
        <template #cell(saldo)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.saldo, 'PEN') }}
          </div>
        </template>
        <template #cell(restante)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.saldo - data.item.consumido, 'PEN') }}
          </div>
        </template>
        <template #cell(consumido)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.consumido, 'PEN') }}
          </div>
        </template>
        <template #cell(totalFlete)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.totalFlete, data.item.moneda) }}
          </div>
        </template>
        <template #cell(subTotal)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.subTotal, data.item.moneda) }}
          </div>
        </template>
        <!-- Column: Actions -->
        <!--<template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="getAmortizar(data)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">AMORTIZAR</span>
            </b-dropdown-item>
             <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarGuia(data)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Generar Guia</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarNota(data)"
              >
                <feather-icon icon="BookIcon" />
                <span class="align-middle ml-50">Generar Nota</span>
              </b-dropdown-item> -->
        <!-- b-dropdown-item
              v-if="data.item.repuestaOse == null"
              target="_blank"
              :href="data.item.repuestaOse == null ?'':data.item.repuestaOse.rutaPdf"
              @click="getConsultaOse(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Consultar Ose</span>
            </b-dropdown-item -->

        <!-- <b-dropdown-item @click="getEdit(data)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Ver</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getDelete(data)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
          </b-dropdown>
        </template> -->
        <template #cell(acciones)="data">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="primary"
              @click="getValesFise(data)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Ver Vales</span>
            </b-button>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="my-modal"
      size="lg"
      hide-footer
      title=""
      no-close-on-backdrop
      no-close-on-esc
      style="max-width: 90%;"
    >
      <div class="d-block">
        <h4 class="text-center">
          NUEVA VALE FISE</h4>
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-body>
            <b-form
              class="p-2"
              @submit.prevent="onSubmit"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="10"
                  class="mb-1 mb-md-0"
                >
                  <b-form-group
                    label="Cliente Final"
                    label-for="clienteFinal"
                  >
                    <v-select
                      v-model="clienteFinal"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nombreComercial"
                      :options="selectClienteFinal"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="mb-1 mb-md-0"
                >
                  <b-form-group
                    label="cantidad Vale"
                    label-for="cantidad Vale"
                  >
                    <b-form-input
                      v-model="cantidadVale"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="text-center"
                align-v="center"
              >
                <b-col
                  cols="12"
                  md="4"
                />
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    type="submit"
                    size="lg"
                  >
                    Guardar
                    <feather-icon icon="SaveIcon" />
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                />
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BSpinner, BAvatar, BMedia, VBTooltip, BBadge, BButton, BFormGroup, BCardBody, BForm,
} from 'bootstrap-vue'
import { jsPDF } from 'jspdf'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import { avatarText } from '@core/utils/filter'
// import moment from 'moment'
// import { params } from 'vee-validate/dist/types/rules/alpha'
//  BCardBody, BMediaAside, BMediaBody, BFormFile, BFormGroup, BButton, , BDropdown, BDropdownItem

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BBadge,
    vSelect,
    BSpinner,
    BAvatar,
    BMedia,
    BCardBody,
    // BMediaAside,
    // BMediaBody,
    // BFormFile,
    BFormGroup,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isBusy: false,
    idEmpresa: 3,
    isToggleSidebar: false,
    dataEdit: {},
    preProveedor: null,
    fileToUpload: null,
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    pedidoSelected: null,
    sortDesc: false,
    sortDirection: 'asc',
    hideModal: false,
    query: '',
    items: [],
    selectProveedor: [],
    statusFilter: { id: '-1', descripcion: 'Todos' },
    statusOptions: [
      { id: '-1', descripcion: 'Todos' },
      { id: '1', descripcion: 'Enviados' },
      { id: '2', descripcion: 'Pendientes' },
      { id: '3', descripcion: 'Vigentes' },
      { id: '4', descripcion: 'Anulados' },
    ],
    userProfile: localStorage.getItem('userProfile'),
    fields: [
      { key: 'cliente_id', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'razon_social', label: 'RAZON SOCIAL' },
      { key: 'nombre_Comercial', label: 'NOMBRE COMERCIAL' },
      { key: 'documento', label: 'DOCUMENTO' },
      { key: 'saldo', label: 'IMPORTE' },
      { key: 'consumido', label: 'CONSUMIDO' },
      { key: 'restante', label: 'SALDO' },
      { key: 'acciones' },
    ],
    transactionData: [
      {
        mode: 'Amazon Web Services, Inc. Invoice',
        types: '437356434540',
        avatar: 'PrinterIcon',
        avatarVariant: 'light-success',
        payment: '10/01/2025',
        deduction: false,
        pdf: '/documentos/compra/COMPRA_2_1736512125959.pdf',
      },
      {
        mode: 'RIOS CHAVEZ CARLOS TACIANO',
        types: '10439286381',
        avatar: 'PrinterIcon',
        avatarVariant: 'light-success',
        payment: '12/01/2025',
        deduction: false,
        pdf: '/documentos/compra/COMPRA_1_1736511475451.pdf',
      },
    ],
    selectClienteFinal: [],
    clienteFinal: null,
    cantidadVale: 0,
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  mounted() {
    this.listarRegistros()
  },
  methods: {
    getPrintPdf(data) {
      console.log(data.item)
      const row = data.item
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('p', 'pt', 'a4', true)

      doc.setProperties({
        title: `PEDIDO DE COMPRA N° ${row.codigo}`,
        author: 'UNIGAS',
        creator: 'UNIGAS',
      })

      // const totalPagesExp = '{total_pages_count_string}'
      const img = row.pedido.almacen.logo
      doc.setFont('arial')
      doc.addImage(img, 'JPEG', 20, 7, 140, 60)
      doc.setFontSize(12)
      doc.text(` ${row.pedido.almacen.descripcion}`, 170, 20)
      doc.setFontSize(8)
      doc.text('Telef. 042-526544 Cel. 970089487', 170, 32)
      doc.text('DIRECCION: JR. FRANCISCO PIZARRO NRO. 860', 170, 43)
      doc.text('RUC: 20362013802', 170, 53)

      doc.setFontSize(12)
      doc.text(`ORDEN COMPRA N°: ${row.codigo}`, 20, 80)
      // doc.text('GUIA N°:', 400, 80)
      doc.setFontSize(9)
      doc.text(`USUARIO: ${row.pedido.trabajador.nombreCompleto}`, 320, 80)
      doc.text(`PEDIDO COMPRA N°: ${row.pedido.codigo}`, 20, 100)
      doc.text(`PROVEEDOR: ${row.proveedor.razonSocial} - ${row.proveedor.ruc}`, 180, 100)
      // doc.text('Forma Pago: ', 470, 100)
      doc.text(`F. REGISTRO: ${row.fechaRegistro}`, 20, 120)
      doc.text(`F. RESPUESTA: ${row.fechaRespuesta == null ? '' : row.fechaRespuesta}`, 180, 120)
      doc.text(` ETAPA: ${row.etapa}`, 320, 120)
      doc.text(` ITEMS(s): ${row.resumen.length}`, 520, 120)
      doc.setFontSize(9)
      doc.text('----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------', 5, 130)
      doc.text('PRODUCTO', 20, 140)
      // doc.text('UND', 334, 140)
      // doc.text('CANT', 365, 140)
      doc.text('UND', 400, 140)
      doc.text('CANT', 445, 140)
      doc.text('P.COMP', 490, 140)
      doc.text('SUBTOTAL', 530, 140)
      doc.text('----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------', 5, 150)
      let initr = 160
      for (let i = 0; i < row.resumen.length; i += 1) {
        const name = (row.resumen[i].producto).substring(0, 100)
        doc.setFontSize(7.5)
        doc.text(name, 20, initr)
        doc.setFontSize(8.5)
        // doc.text(row.resumen[i].unidadmedida.substring(0, 4), 335, initr)
        // doc.text(`${row.resumen[i].cantidad_solicitada}`, 366, initr)
        doc.text(`${row.resumen[i].unidadmedida.substring(0, 4)}`, 400, initr)
        doc.text(`${parseFloat(row.resumen[i].cantidad).toFixed(2)}`, 445, initr)
        doc.text(`${parseFloat(row.resumen[i].precio).toFixed(2)}`, 490, initr)
        doc.text(`${parseFloat(row.resumen[i].subtotal).toFixed(2)} `, 530, initr)
        initr = Number(initr) + 13
        if (initr + 13 >= 813) {
          doc.addPage()
          initr = 35
        }
      }

      doc.setFontSize(9)

      initr = Number(initr) + 15
      if (initr + 13 >= 813) {
        doc.addPage()
        initr = 35
      }
      doc.text('OBSERVACION:', 20, initr)
      doc.text(`${row.observacion == null ? '' : row.observacion}`, 20, initr + 13)

      /* $lineas_observaciones = data[0].observacion.split('<br>');
        $tam_observaciones = $lineas_observaciones.length;
        for ($j=0; $j < $tam_observaciones ; $j++) {
            $texto_observacion = $lineas_observaciones[$j];
            doc.text($texto_observacion, 45, initr);
            initr=parseInt(initr) + 13;
        }
           */
      /*  var tipoigv = '' ;
      if(data[0].tipo_igv==0){       tipoigv = '* SIN IGV' ; }
      else if(data[0].tipo_igv==1){  tipoigv = '* IGV INCLUIDO' ;  }
      else if(data[0].tipo_igv==2){  tipoigv = '* IGV INAFECTO' ;             }
      doc.text(tipoigv +"      ", 20, initr + 30); */
      doc.setFontSize(11)
      const simbolMoneda = 'S/' // row.codmoneda === 'PEN' ? 'S/' : '$/'
      doc.text(` Sub total ${simbolMoneda} ${parseFloat(row.subTotal).toFixed(2)}  |   Total ${simbolMoneda} ${parseFloat(row.total).toFixed(2)}`, 20, initr + 40)
      /* if (row.etapa === 'ACEPTADO') {
        const { firma } = row.pedido.trabajador
        if (firma !== null && firma !== '') {
          doc.addImage(firma, 'JPEG', 155, initr + 100, 100, 50)
        }
        doc.text('------------------------------', 150, initr + 150)
        doc.text('SOLICITANTE', 165, initr + 160)

        doc.text('------------------------------', 350, initr + 150)
        doc.text('GERENTE', 370, initr + 160)
      } */
      // doc.putTotalPages(totalPagesExp)
      const blob = doc.output('blob')
      window.open(URL.createObjectURL(blob))
    },

    async listarRegistros() {
      this.isBusy = true
      await store
        .dispatch('comercial/CONTROL_FISE_SALDO_ALL', {
          tipo: 'grilla',
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: '',
          cliente_id: '-1',
        })
        .then(response => {
          // this.items = response.items.map(venta => ({ ...venta, clienteRuc: venta.venta.clienteDato.split('|')[2], clienteNombre: venta.venta.clienteDato.split('|')[0] }))
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
          this.selectClienteFinal = []
          this.selectClienteFinal = response.cliente
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
    setCotizciones(data) {
      this.pedidoSelected = data.item
      this.transactionData = []
      this.pedidoSelected.resumenCotizacion.forEach(cotiz => {
        const rowCotiz = {
          mode: cotiz.proveedor,
          types: cotiz.ruc,
          avatar: 'PrinterIcon',
          avatarVariant: 'light-success',
          payment: cotiz.fecharegistro,
          deduction: false,
          pdf: cotiz.url,
          cotizacion: cotiz,
        }
        this.transactionData.push(rowCotiz)
      })
      this.$refs['my-modal'].show()
    },

    formatearMoneda(valor, moneda) {
      // Convertir el número en cadena con dos decimales
      const valorFormateado = valor.toFixed(2)
      let respose = `S/. ${valorFormateado}`
      if (moneda === 'DOLARES') {
        respose = `$/. ${valorFormateado}`
      }
      return respose
      // Agregar el prefijo "S/." y retornar
    },

    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    // deleteVenta
    // generarGuia
    // generarNota
    // getConsultaOse
    getConsultaOse(data) {
      console.info('Consulta', data)
      const paramss = { nro_comprobante: data.item.numero, serie: data.item.serie, tipo_documento: data.item.tipoComprobante, venta_id: 0, guia_remision_id: data.item.guiaRemisionId }
      this.$swal({
        title: 'Consultando en la Ose',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/VENTA_CONSULTA_OSE'
          await store.dispatch(service, paramss)
            .then(response => {
              this.items.forEach((item, index) => {
                if (item.ventaId === data.item.ventaId) {
                  this.items[index].repuestaOse = response.respuesta_ose
                }
              })
              this.$swal.close()
              this.showToast('Operación Extitosa', 'El comprobante esta enviado en la Ose', 'success')
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              this.showToast('Operación Error', error.errorMessage, 'danger')
            })
        },
      })
    },
    chageStatusFilter() {
      console.log(this.statusFilter)
      this.listarRegistros()
    },
    getAmortizar(data) {
      console.log(data)
      this.$refs['my-modal'].show()
    },
    getValesFise(data) {
      this.$router.push({ name: 'rt-cobranza-creditofise', params: { clienteId: data.item.cliente_id } })
    },
    openModalControlFise(tipo) {
      console.log(tipo)
      this.cantidadVale = 0
      // resumenControlFise
      this.clienteFinal = null
      // console.log(this.almacen)
      this.$refs['my-modal'].show()
    },
    onSubmit() {
      try {
        console.log(this.clienteFinal)
        console.log(this.cantidadVale)
        if (this.clienteFinal === null) {
          throw new Error('TIENE QUE SELECIONAR UN CLIENTE')
        }
        if (this.cantidadVale === 0 || this.cantidadVale === null) {
          throw new Error('TIENE QUE INGRESAR LAS CANTIDADES DE VALES')
        }
        this.$swal({
          title: 'Registrando los vales fise',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            this.$swal.showLoading()
            const service = 'comercial/CONTROL_FISE_MASIVO_CREATE'
            // formData.value.presentacion = presentacionSel.value
            const formVenta = { cantidad: this.cantidadVale, cliente_id: this.clienteFinal.idCliente }
            await store.dispatch(service, formVenta)
              .then(response => {
                this.$swal.close()
                this.$refs['my-modal'].hide()
                this.showToast('Registro', response.message, 'success')
                this.listarRegistros()
              })
              .catch(error => {
                this.$swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        this.$swal.close()
        this.showToast('Ha ocurrido un error', error.message, 'danger')
      }
    },
    avatarText,
    showToast,
    showError,
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style>
  .w200{
    width: 350px !important;
  }

  </style>
